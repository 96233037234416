import React, { useState, useEffect, FC } from "react";

import { PDFDownloadLink } from "@react-pdf/renderer";
import { Document as ReactPDFDocument, Page as ReactPDFPage } from "react-pdf"; // Import from 'react-pdf' for rendering

interface IPDFViewerProps {
  PDFfile: string;
}

const PDFViewer: FC<IPDFViewerProps> = ({ PDFfile }) => {
  return (
    <div>
      <h1>PDF Preview</h1>

      <div>
        <ReactPDFDocument file={PDFfile}>
          <ReactPDFPage pageNumber={1} />
        </ReactPDFDocument>
      </div>

      {/* PDF download link */}
      {/* <PDFDownloadLink document={<MyPDFDocument />} fileName="my_document.pdf">
        {({ loading }) => (loading ? "Generating PDF..." : "Download PDF")}
      </PDFDownloadLink> */}
    </div>
  );
};

export default PDFViewer;
