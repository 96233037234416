// ConfirmDialog.tsx
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Colors } from "../../../configs";
import { Typography } from "@mui/material";
import { CustomButton } from "../button/button";
import { pxToRem } from "../../../util";

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  message: string | React.ReactNode;
  positiveButtonText?: string;
  negativeButtonText?: string;
  additionalButton?: {
    text: string;
    onClick: () => void;
  };
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  title,
  message,
  positiveButtonText = "Confirm",
  negativeButtonText = "Cancel",
  onCancel,
  onConfirm,
  additionalButton,
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle
        sx={{
          fontSize: "1.7rem !important",
          fontWeight: 600,
          color: Colors.TEXT_GRAY_DARK,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            fontSize: "1.5rem !important",
            "& *": {
              fontSize: "1.5rem !important",
            },
            color: Colors.TEXT_GRAY_DARK,
            marginBottom: "1.5rem",
            marginTop: "0.5rem",
            opacity: 0.8,
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "stretch",
          gap: pxToRem(10),
          pl: pxToRem(100),
        }}
      >
        <CustomButton
          onClick={onCancel}
          sx={{
            fontSize: "1.5rem",
            textTransform: "none",
            background: "none",
            color: Colors.PRIMARY,
            whiteSpace: "nowrap",
          }}
        >
          {negativeButtonText}
        </CustomButton>
        {additionalButton && (
          <CustomButton
            onClick={additionalButton.onClick}
            sx={{
              fontSize: "1.5rem",
              textTransform: "none",
              background: "none",
              color: Colors.PRIMARY,
              border: `${pxToRem(3)} solid ${Colors.PRIMARY}`,
              boxSizing: "border-box",
              fontWeight: 500,
              whiteSpace: "nowrap",
            }}
          >
            {additionalButton.text}
          </CustomButton>
        )}
        <CustomButton
          onClick={onConfirm}
          autoFocus
          sx={{
            fontSize: "1.5rem",
            textTransform: "none",
            paddingInline: "2rem",
            whiteSpace: "nowrap",
          }}
        >
          {positiveButtonText}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
