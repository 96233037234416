import { FC, useEffect, useRef } from "react";
import { Grid, Box } from "@mui/material";

import CartRow from "./cart-row";
import { Colors } from "../../../../configs";
import { CartItem } from "../../../../typings/interfaces/pos/cart-item";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";

export interface ICartSectionProps {
  onSelect(index: number): void;
}

const CartSection: FC<ICartSectionProps> = ({ onSelect }) => {
  const { selectedSession } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  // const { getDryCleanPrice } = usePricing();

  const handleOnCartItemClick = (item: CartItem) => {
    dispatch(CartSliceAction.setSelectedCartItem(item));
  };

  const boxRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedSession?.cartItems?.filter((row) => row.finalized).length]);

  const styles = {
    tableHeadingCell: {
      borderRadius: "0.71rem",
      background: Colors.SECONDARY,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem 0.71rem",
      boxSizing: "border-box",
      color: "white",
    },
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        padding: "0.71rem",
        gap: "1.14rem",
        height: "28.42rem",
      }}
    >
      <Box>
        <Grid container sx={{ gap: "1.21428571rem", height: "100%" }}>
          <Grid item xs={12}>
            <Box style={{ position: "sticky", top: 0 }}>
              <Grid
                container
                sx={{
                  gap: "0.35rem",
                  flexWrap: "nowrap",
                  paddingRight: "0.5rem",
                }}
              >
                <Grid item xs={1} sx={styles.tableHeadingCell}>
                  Qty
                </Grid>
                <Grid item xs={6.7} sx={styles.tableHeadingCell}>
                  Description
                </Grid>
                <Grid item xs={1.7} sx={styles.tableHeadingCell}>
                  Status
                </Grid>
                <Grid item xs={1.3} sx={styles.tableHeadingCell}>
                  Unit Price
                </Grid>
                <Grid item xs={1.3} sx={styles.tableHeadingCell}>
                  Total
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: "22.9rem" }}>
            <Box
              ref={boxRef}
              sx={{
                height: "22.9rem",
                overflowY: "auto",
                "&::-webkit-scrollbar": { width: "0.5rem" },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ADADAD",
                  borderRadius: "0rem",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "0rem",
                },
                // make scrolling animation smooth
                scrollBehavior: "smooth",
              }}
            >
              {selectedSession?.cartItems
                // ?.filter((item) => item.finalized)
                ?.map((cartItem, i) => {
                  return (
                    <CartRow
                      key={i}
                      onSelect={() => handleOnCartItemClick(cartItem)}
                      cartItem={cartItem}
                      // data={
                      // {
                      // qty: cartItem.qty,
                      // serviceType: cartItem?.serviceType?.categoryName || "",
                      // textureType: cartItem.texture || "",
                      // color: cartItem.garmentColor || [],
                      // materialType: cartItem.materialType?.name || "",
                      // clothSubType: cartItem?.garmentType?.name || "",
                      // clothType: cartItem?.garmentStyle?.name || "",
                      // instructions: cartItem.instructions,
                      // damages: cartItem.damages,
                      // upcharges: cartItem.upcharges || [],
                      // status: "In Progress",
                      // unitPrice: cartItem.unitPrice,
                      // note: cartItem.note,
                      // }
                      // }
                      status="In Progress"
                      firstRow={i === 0}
                      lastRow={
                        i ===
                        selectedSession.cartItems?.filter(
                          (item) => item.finalized
                        ).length -
                          1
                      }
                    />
                  );
                })}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CartSection;
