import { FC, useCallback, useEffect, useState } from "react";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../../configs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { DraggableCard } from "../../atoms/draggable-card/draggable-card";
import { CustomButton, CustomTextBox } from "../../atoms";
import { Height } from "@mui/icons-material";
import { Controller, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createNewColorPinTagAction,
  getAllColorPinTagsAction,
  updateColorPinTagAction,
} from "../../../redux/actions/pintagAction";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { IColorPintag } from "../../../typings/interfaces/pos/pintag";

interface IAddPinTagDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  storeId: number;
  editMode?: boolean;
  populateData?: IColorPintag | undefined;
}

const colors = [
  {
    label: "Rich Electric Blue",
    value: "#0892d0",
  },
  {
    label: "American Green",
    value: "#34b233",
  },
  {
    label: "Cambridge Blue",
    value: "#a3c4bc",
  },
  {
    label: "Violet-Blue",
    value: "#8a2be2",
  },
  {
    label: "California Gold",
    value: "#e3a857",
  },
  {
    label: "Light hot pink",
    value: "#ff69b4",
  },
  {
    label: "Paradies Pink",
    value: "#e44242",
  },
  {
    label: "Misty Moss",
    value: "#b0c4de",
  },
  {
    label: "Water",
    value: "#add8e6",
  },
  {
    label: "Bitter Lemon",
    value: "#bfff00",
  },
];

const schema = Yup.object().shape({
  color: Yup.string().required("Color is required"),
  description: Yup.string().required("Description is required"),
  alias: Yup.string()
    .test(
      "len",
      "Identification must be at least 1 and maximum 2 characters",
      (val) => (val?.length ?? 0) > 0 && (val?.length ?? 0) <= 2
    )
    .required("Identification is required"),
});

type FormData = Yup.InferType<typeof schema>;
const resolver: Resolver<FormData> = yupResolver(schema);

export const AddPinTagDrawer: FC<IAddPinTagDrawerProps> = ({
  open,
  toggleDrawer,
  storeId,
  editMode = false,
  populateData,
}) => {
  const dispatch = useAppDispatch();
  const [filteredColors, setFilteredColors] = useState(colors);
  const { control, reset, setValue, handleSubmit } = useForm<FormData>({
    resolver,
  });
  const { getColorPintags, addColorPintagLoading, updateColorPintagLoading } =
    useAppSelector((state) => state.pintag);

  useEffect(() => {
    if (!getColorPintags?.body || !getColorPintags) {
      dispatch(getAllColorPinTagsAction());
    }
  }, []);
  useEffect(() => {
    if (getColorPintags?.body) {
      // assign the colors which are not already in the getColorPintags.body to filteredColors
      const pintagColors = getColorPintags.body.map((pintag) => pintag.color);

      const newFilteredColors = colors.filter(
        (color) => !pintagColors.includes(color.value)
      );

      setFilteredColors(newFilteredColors);
    }
  }, [getColorPintags]);

  const snackbar = useSnackbarContext();

  useEffect(() => {
    if (editMode && populateData) {
      setValue("color", populateData.color);
      setValue("description", populateData.description);
      setValue("alias", populateData.alias);
    }
  }, [populateData, editMode]);

  const handleOnSubmit = (data: FormData) => {
    if (editMode && populateData) {
      dispatch(
        updateColorPinTagAction({
          id: populateData.id,
          color: data.color,
          description: data.description,
          alias: data.alias,
          orderIndex: populateData.orderIndex,
        })
      )
        .then(() => {
          snackbar.showSnackbar("Color PinTag updated successfully", "success");
          dispatch(getAllColorPinTagsAction());
          setValue("color", filteredColors?.[0]?.value ?? "");
          setValue("description", "");
          setValue("alias", "");
          toggleDrawer();
        })
        .catch((err) => {
          snackbar.showSnackbar(err.message, "error");
        });
    } else {
      dispatch(
        createNewColorPinTagAction({
          color: data.color,
          description: data.description,
          alias: data.alias,
          orderIndex: 0,
        })
      )
        .then(() => {
          snackbar.showSnackbar("Color PinTag added successfully", "success");
          dispatch(getAllColorPinTagsAction());
          setValue("color", filteredColors?.[0]?.value ?? "");
          setValue("description", "");
          setValue("alias", "");
          toggleDrawer();
        })
        .catch((err) => {
          snackbar.showSnackbar(err.message, "error");
        });
    }
  };

  return (
    <CustomDrawer
      isOpen={open}
      toggleDrawer={toggleDrawer}
      sx={{
        width: "34.2857143rem",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "1.71428571rem",
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "3.57142857rem",
          height: "100%",
          flexGrow: 1,
        }}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <IconButton
          onClick={toggleDrawer}
          sx={{
            alignSelf: "flex-end",
            background: "none",
            border: `0.2rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
            width: "2.5rem",
            height: "2.5rem",
          }}
        >
          <CloseIcon
            sx={{ fontSize: "1.8rem", color: Colors.ACCENT_GRAY_DISABLED }}
          />
        </IconButton>
        <Typography
          fontWeight={600}
          color={Colors.TEXT_GRAY_DARK}
          sx={{ fontSize: "2rem" }}
        >
          {editMode ? "Edit" : "Add New"} Pintag
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.71428571rem",
          }}
        >
          <Controller
            name="color"
            control={control}
            defaultValue={filteredColors?.[0]?.value ?? ""}
            render={({ field, fieldState }) => (
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  fontSize: "1.25rem",
                }}
              >
                Color
                <Select
                  {...field}
                  error={fieldState.invalid}
                  // onChange={handleChange}
                  // input={<OutlinedInput label="Color" />}
                  // label="Color"
                  // renderValue={(selected) => selected.join(', ')}
                  // MenuProps={MenuProps}
                  sx={{
                    // width: "100%",
                    ".MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      gap: "3rem",
                    },
                  }}
                >
                  {filteredColors.map((color, i) => (
                    <MenuItem
                      key={i}
                      value={color.value}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3rem",
                      }}
                    >
                      <Box
                        sx={{
                          background: color.value,
                          width: "6rem",
                          height: "1rem",
                        }}
                      />
                      <ListItemText primary={color.label} />
                    </MenuItem>
                  ))}
                </Select>
              </label>
            )}
          />
          {/* <label
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              fontSize: "1.25rem",
            }}
          >
            Color
            
          </label> */}
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  fontSize: "1.25rem",
                }}
              >
                Description
                <CustomTextBox
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              </label>
            )}
          />
          <Controller
            name="alias"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  fontSize: "1.25rem",
                }}
              >
                Identification
                <CustomTextBox
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              </label>
            )}
          />
        </Box>
        <CustomButton
          sx={{ mt: "auto", py: "0.8rem", fontSize: "1.4rem" }}
          type="submit"
        >
          {addColorPintagLoading || updateColorPintagLoading ? (
            <CircularProgress
              sx={{
                width: "1.5rem",
                height: "1.5rem",
                maxHeight: "1.5rem",
                maxWidth: "1.5rem",
                fontSize: "1.5rem",
              }}
            />
          ) : (
            "Save Pintag Color"
          )}
        </CustomButton>
      </form>
    </CustomDrawer>
  );
};
