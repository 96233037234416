export const Colors = {
  PRIMARY: "#018752",
  SECONDARY: "#0079C2",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  ACCENT_GRAY: "#D9D9D9",
  ACCENT_GRAY_DISABLED: "#B3B3B3",
  BACKGROUND_GRAY: "#F8F8F8",
  BACKSHADE_GRAY: "#F4F4F4", 
  MAIN_GRAY: "#515151",
  TEXT_GRAY_DARK: "#4D4D4D",
  BROWN: "#930000",
  COLOR_BUTTON_BORDER: "#808080",
  LIGHT_YELLOW: "#F9FFB0",
  NOTIFIC_RED: "#EFD8D8",
  NOTIFIC_GREEN: "#D8EFD9",
  ERROR_RED: "#EE0000",
  SUCCESS_GREEN: "#00AA00",
};
