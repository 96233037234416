import { FC, useState } from "react";
import { PosLayout } from "../../../components/layouts/pos-layout/PosLayout";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  Table,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
} from "chart.js";
import { Radar, Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import DatePicker from "react-datepicker";

import { Colors } from "../../../configs";
import CustomerDetailSection from "../../../components/common/pos/customer-detail-section/customer-detail-section";
import { formatCurrency, formatUSDate } from "../../../util/common-functions";
import TopRightButton from "../../../components/common/top-right-button/top-right-button";
import { useNavigate, useParams } from "react-router-dom";
import PdfViewModal from "../../../components/pos/pdf-view-modal/pdf-view-modal";

import "react-datepicker/dist/react-datepicker.css";

export interface ICustomerAnalysisProps {}

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Filler,
  Tooltip,
  Legend,
  Title
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const lineChartData = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.number.int({ min: -1000, max: 1000 })),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.number.int({ min: -1000, max: 1000 })),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const radarChartData = {
  labels: ["January", "February", "March", "April", "may", "June", "July"],
  datasets: [
    {
      label: "Skip first dataset",
      data: [0, 40, 5, 50, 5, 90, 10],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 1,
    },
    {
      label: "Skip mid dataset",
      data: [70, 20, 20, 0, 30, 20, 25],
      backgroundColor: "rgba(128, 168, 255, 0.2)",
      borderColor: "rgba(128, 168, 255, 1)",
      borderWidth: 1,
    },
    {
      label: "Skip last dataset",
      data: [45, 40, 90, 5, 10, 90, 0],
      backgroundColor: "rgba(128, 255, 168, 0.2)",
      borderColor: "rgba(128, 255, 168, 1)",
      borderWidth: 1,
    },
  ],
};

const CustomerAnalysisScreen: FC<ICustomerAnalysisProps> = ({}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState<string | null>(null);

  const [startDate, endDate] = dateRange;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const handleOpenPdf = (report: string) => {
    setSelectedReport(report);
    setOpenPdfModal(true);
  };

  const handleClosePdfModal = () => {
    setOpenPdfModal(false);
    setSelectedReport(null);
  };

  const navigate = useNavigate();
  const params = useParams();
  const customerId = params.id;

  return (
    <PosLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          gap: "0.57rem",
        }}
      >
        <Box
          sx={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
            gap: "1.78rem",
            height: "100%",
            maxHeight: "100% !important",
          }}
        >
          <CustomerDetailSection
            showAnalysisButton={true}
            showEditButton={true}
            showHistoryButton={true}
          />
          <Box
            sx={{
              width: "100%",
              height: "50.65rem",
              borderRadius: "0.71rem",
              background: Colors.BACKSHADE_GRAY,
              padding: "2.78rem 2.14rem",
              display: "flex",
              flexDirection: "column",
              gap: "2.28rem",
            }}
          >
            <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
              Customer Insights
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                rowGap: "1.16rem",
                gap: "0.89rem",
              }}
            >
              <Box
                sx={{
                  background: Colors.SECONDARY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "53.64rem",
                  color: Colors.WHITE,
                  display: "flex",
                  flexDirection: "column",
                  gap: "2.28rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                    fontWeight: "bold",
                  }}
                >
                  Last Visit
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1.14rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.71rem",
                        lineHeight: "100%",
                      }}
                    >
                      Date
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.71rem", lineHeight: "100%" }}
                    >
                      {formatUSDate(new Date())}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1.14rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.71rem",
                        lineHeight: "100%",
                      }}
                    >
                      Payment
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.71rem", lineHeight: "100%" }}
                    >
                      {formatCurrency({ amount: 200 })}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1.14rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.71rem",
                        lineHeight: "100%",
                      }}
                    >
                      Number of Jobs
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.71rem", lineHeight: "100%" }}
                    >
                      4
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  background: Colors.PRIMARY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.WHITE,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Since
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  2001 Oct
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Total Spend
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {formatCurrency({ amount: 5300 })}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Total Jobs
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  69
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Weekly Avg
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {formatCurrency({ amount: 10 })}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Monthly Avg
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {formatCurrency({ amount: 43.4 })}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Yearly Avg
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {formatCurrency({ amount: 2560 })}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "0.14rem", background: Colors.ACCENT_GRAY }}></Box>
        <Box
          sx={{
            flex: 5,
            display: "flex",
            flexDirection: "column",
            gap: "3.71rem",
          }}
        >
          <TopRightButton
            variation="Back"
            onClick={() => navigate(`/pos/${customerId}`)}
          />
          <Typography
            sx={{ fontWeight: "bold", fontSize: "2.57rem", lineHeight: "100%" }}
          >
            Customer Analysis Reports
          </Typography>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            sx={{
              marginTop: "-2.5rem",
              marginBottom: 0,
              "& .MuiTab-root": {
                background: Colors.BACKSHADE_GRAY,
                color: Colors.PRIMARY,
                height: "2.85714285714rem",
                minHeight: "2.85714285714rem",
                width: "10.6778571429rem",
                padding: 0,
              },
              "& .Mui-selected": {
                background: Colors.PRIMARY,
                color: `${Colors.WHITE} !important`,
              },
            }}
          >
            <Tab
              label="Analysis"
              sx={{
                fontWeight: "bold",
                fontSize: "1.14285714286rem",
                fontFamily: "Sebino",
                textTransform: "none",
                borderTopLeftRadius: "0.71rem",
                borderBottom: 0,
              }}
            />
            <Tab
              label="Reports"
              sx={{
                fontWeight: "bold",
                fontSize: "1.14285714286rem",
                fontFamily: "Sebino",
                textTransform: "none",
                borderRadius: 0,
                borderBottom: 0,
              }}
            />
            <Tab
              label="Tab3"
              sx={{
                fontWeight: "bold",
                fontSize: "1.14285714286rem",
                fontFamily: "Sebino",
                textTransform: "none",
                borderTopRightRadius: "0.71rem",
                borderBottom: 0,
              }}
            />
          </Tabs>

          {/* LARGE CONTAINER THAT HAS ALL RIGHT SIDE */}
          <Box
            sx={{
              background: Colors.BACKSHADE_GRAY,
              borderRadius: "0 0.71rem 0.71rem 0.71rem",
              padding: "2rem 1.07rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "0.71rem",
              rowGap: "1.21rem",
              marginTop: "-6rem",
            }}
          >
            {selectedTab === 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "0.71rem",
                  rowGap: "1.21rem",
                }}
              >
                {/* LEFT TOP SECTION */}
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Box
                    sx={{
                      background: Colors.WHITE,
                      borderRadius: "0.71rem",
                      width: "22.857rem",
                      maxHeight: "10.928rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Line options={options} data={lineChartData} />
                  </Box>
                  <Box
                    sx={{
                      background: Colors.WHITE,
                      borderRadius: "0.71rem",
                      width: "22.857rem",
                      maxHeight: "10.928rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Line options={options} data={lineChartData} />
                  </Box>
                </Box>

                {/* RIGHT TOP SECTION */}
                <Box
                  sx={{
                    background: Colors.WHITE,
                    borderRadius: "0.71rem",
                    width: "22.857rem",
                    maxHeight: "22.857rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Radar data={radarChartData} />
                </Box>

                <Box
                  sx={{
                    background: Colors.WHITE,
                    borderRadius: "0.71rem",
                    width: "22.857rem",
                    maxHeight: "22.857rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Radar data={radarChartData} />
                </Box>

                {/* BOTTOM SECTION */}
                <Box
                  sx={{
                    background: Colors.WHITE,
                    borderRadius: "0.71rem",
                    width: "71rem",
                    maxHeight: "35.714rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Line options={options} data={lineChartData} />
                </Box>
              </Box>
            )}

            {selectedTab === 1 && (
              <>
                {/* Reports tab Top Container */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: Colors.WHITE,
                    padding: "1rem",
                    borderRadius: "0.71rem",
                    marginBottom: "1rem",
                    width: "100%",
                    minHeight: "7.64285714286rem",
                    marginLeft: "2rem",
                    marginRight: "2rem",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "18rem" }}
                  >
                    <Typography variant="body1" sx={{ mb: "0.5rem" }}>
                      Select Date:
                    </Typography>

                    <DatePicker
                      selectsRange={true}
                      startDate={startDate ?? undefined}
                      endDate={endDate ?? undefined}
                      onChange={(update: [Date | null, Date | null]) => {
                        setDateRange(update);
                      }}
                      isClearable={false}
                      customInput={
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  marginRight: 0,
                                  marginLeft: "-0.5",
                                }}
                              >
                                <CalendarTodayIcon
                                  sx={{
                                    fontSize: "1rem",
                                    marginRight: "0.8rem",
                                  }}
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{
                                  marginRight: "-0.5",
                                }}
                              >
                                <KeyboardArrowDownIcon />
                              </InputAdornment>
                            ),
                            sx: { height: "2.214rem", bgcolor: "white" },
                          }}
                        />
                      }
                    />
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "15rem", marginRight: "1rem" }}
                  >
                    <Typography variant="body1" sx={{ mb: "0.5rem" }}>
                      Invoice No:
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        sx: { height: "2.214rem", bgcolor: "white" },
                      }}
                    />
                  </Box>
                </Box>

                {/* Bottom Container for Table */}
                <Box
                  sx={{
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    padding: "1rem",
                    borderRadius: "0.71rem",
                    width: "100%",
                    minHeight: "49.5rem",
                  }}
                >
                  <Box
                    sx={{
                      height: "45rem",
                      overflowY: "auto",
                      overflowX: "hidden",
                      paddingRight: "1rem",
                      paddingLeft: "1rem",
                      "&::-webkit-scrollbar": { width: "0.5rem" },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: Colors.ACCENT_GRAY_DISABLED,
                        borderRadius: "0.71rem",
                        border: "2px solid transparent",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                        borderRadius: "0rem",
                        margin: "20rem",
                      },
                    }}
                  >
                    <TableContainer sx={{ width: "100%" }}>
                      <Table sx={{ width: "100%" }}>
                        <TableBody>
                          {[
                            "Daily Report - RP454.pdf",
                            "Daily Report - RP453.pdf",
                            "Daily Report - RP452.pdf",
                            "Daily Report - RP451.pdf",
                            "Daily Report - RP454.pdf",
                            "Daily Report - RP453.pdf",
                            "Daily Report - RP452.pdf",
                            "Daily Report - RP451.pdf",
                            "Daily Report - RP454.pdf",
                            "Daily Report - RP453.pdf",
                            "Daily Report - RP452.pdf",
                            "Daily Report - RP451.pdf",
                          ].map((report, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                width: "100%",
                                marginBottom: "1.5rem",
                                borderRadius: "0.71rem",
                                backgroundColor: Colors.WHITE,
                                borderBottom: `4px solid ${Colors.BACKSHADE_GRAY}`,
                                "&:last-child": {
                                  marginBottom: 0,
                                },
                              }}
                            >
                              <TableCell
                                sx={{
                                  padding: "1rem",
                                  paddingLeft: "2rem",
                                  width: "70%",
                                  borderBottom: "none",
                                  borderRadius: " 0.71rem",
                                  overflow: "hidden",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "1.25rem" }}
                                >
                                  {report}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  padding: "1rem",
                                  borderBottom: "none",
                                  borderRadius: " 0.71rem ",
                                }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-evenly"
                                >
                                  <IconButton
                                    sx={{
                                      backgroundColor: Colors.WHITE,
                                      border: `1px solid ${Colors.PRIMARY}`,
                                      borderRadius: "0.5rem",
                                      padding: "0.5rem",
                                      width: "3.857rem",
                                      height: "2.357rem",
                                    }}
                                    onClick={() =>
                                      handleOpenPdf("Daily Report - RP454.pdf")
                                    }
                                  >
                                    <FileOpenIcon
                                      sx={{
                                        fontSize: "1.571rem",
                                        color: Colors.PRIMARY,
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      backgroundColor: Colors.WHITE,
                                      border: `1px solid ${Colors.PRIMARY}`,
                                      borderRadius: "0.5rem",
                                      padding: "0.5rem",
                                      width: "3.857rem",
                                      height: "2.357rem",
                                    }}
                                  >
                                    <DownloadIcon
                                      sx={{
                                        fontSize: "1.571rem",
                                        color: Colors.PRIMARY,
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      backgroundColor: Colors.WHITE,
                                      border: `1px solid ${Colors.PRIMARY}`,
                                      borderRadius: "0.5rem",
                                      padding: "0.5rem",
                                      width: "3.857rem",
                                      height: "2.357rem",
                                    }}
                                  >
                                    <PrintIcon
                                      sx={{
                                        fontSize: "1.571rem",
                                        color: Colors.PRIMARY,
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                                {/* Render the PDF modal */}
                                {openPdfModal && (
                                  <PdfViewModal
                                    open={openPdfModal}
                                    onClose={handleClosePdfModal}
                                    reportName={selectedReport}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </>
            )}

            {selectedTab === 2 && <Typography>Content for Tab 3</Typography>}
          </Box>
        </Box>
      </Box>
    </PosLayout>
  );
};

export default CustomerAnalysisScreen;
