import {
  Page,
  Text,
  View,
  Document as PDFDocument,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { pdfjs } from "react-pdf"; // Import from 'react-pdf' for rendering

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const styles = StyleSheet.create({
  page: { padding: 30 },
  section: { marginBottom: 20 },
});

const ReportPDFTemplate = () => (
  <PDFDocument>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Section 1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section 2</Text>
      </View>
    </Page>
  </PDFDocument>
);

export { ReportPDFTemplate };
