import { FC, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";

import CustomerHistoryTableRow from "./customer-history-table-row";
import { Colors } from "../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getCustomerBillHistoryAction } from "../../../../redux/actions/customerAction";
import InvoiceHistoryModal from "../../../pos/invoice-history-modal/invoice-history-modal";
import { useParams } from "react-router-dom";
import { CustomerBillsResponse } from "../../../../typings";
import { getBillByIdAction } from "../../../../redux/actions/cartAction";
import TouchAppIcon from "@mui/icons-material/TouchApp";

export interface ICustomerHistoryTableProps {
  contentHeight?: string;
}

const CustomerHistoryTable: FC<ICustomerHistoryTableProps> = ({
  contentHeight = "39.07rem",
}) => {
  const params = useParams();
  const customerId = params.id;
  const queryParams = new URLSearchParams(window.location.search);
  const billId = queryParams.get("billId");
  const dispatch = useAppDispatch();
  const { bills, billsLoading } = useAppSelector((state) => state.customer);
  const [clickedRow, setClickedRow] = useState<CustomerBillsResponse | null>(
    null
  );

  const [openModal, setOpenModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] =
    useState<CustomerBillsResponse | null>(null);

  // useEffect(() => {
  //   if (billId)
  //     setClickedRow({
  //       billId: billId,
  //       id: Number(billId),
  //     });
  // }, [billId]);

  useEffect(() => {
    if (billId) {
      setClickedRow({
        billId: billId,
        id: Number(billId),
        date: "12/12/2023",        // Sample date
        status: "Paid",            // Sample status
        cashier: "Cashier 01",     // Sample cashier name
        details: "Sample description of the invoice.", // Sample details
        amount: 150.75             // Sample amount
      });
    }
  }, [billId]);
  

  useEffect(() => {
    if (clickedRow?.id)
      dispatch(getBillByIdAction({ billId: String(clickedRow?.id) }));
  }, [clickedRow]);

  useEffect(() => {
    getCustommerBillList();
  }, [customerId]);

  const getCustommerBillList = () => {
    if (customerId) dispatch(getCustomerBillHistoryAction(customerId));
  };
  const styles = {
    tableHeadingCell: {
      // borderRadius: "0.71rem",
      borderRadius: "0",
      background: Colors.SECONDARY,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem 0.71rem",
      boxSizing: "border-box",
      color: "white",
      height: "2.92857142857rem",
    },
  };

  const handleOnClickRow = (data: CustomerBillsResponse) => {
    setClickedRow(data);
  };

  const handleViewClick = (data: CustomerBillsResponse) => {
    setSelectedInvoice(data);
    setOpenModal(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0",
        padding: "0.71rem",
        gap: "1.14rem",
        height: "28.42rem",
      }}
    >
      <Box>
        <Grid container sx={{ gap: "0.21rem", height: "100%" }}>
          <Grid item xs={12}>
            <Box style={{ position: "sticky", top: 0 }}>
              <Grid container sx={{ gap: "0.15rem", flexWrap: "nowrap" }}>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "2.92857142857rem",
                    maxWidth: "2.92857142857rem !important",
                    borderTopLeftRadius: "0.71rem",
                  }}
                >
                  &nbsp;
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Invoice
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Date In
                </Grid>
                {/* cashier column */}
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Cashier
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Due date
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pieces
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "4.606rem",
                    maxWidth: "4.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Rack
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Balance
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pay Date
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pickup Date
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.246rem",
                    maxWidth: "5.246rem !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopRightRadius: "0.71rem",
                  }}
                >
                  <TouchAppIcon sx={{ color: "white" }} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: contentHeight }}>
            <Box
              sx={{
                height: contentHeight,
                overflowY: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": { width: "0.5rem" },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ADADAD",
                  borderRadius: "0rem",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "0rem",
                },
              }}
            >
              {bills?.map((item) => (
                <CustomerHistoryTableRow
                  isSelected={clickedRow?.id === item.id}
                  data={item}
                  firstRow={bills?.indexOf(item) === 0}
                  lastRow={bills?.indexOf(item) === bills.length - 1}
                  onClick={handleOnClickRow}
                  onViewClick={handleViewClick}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Render InvoiceHistoryModal */}
      {selectedInvoice && (
        <InvoiceHistoryModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          // invoiceData={selectedInvoice}
          billId={selectedInvoice.billId} 
        />
      )}
    </Box>
  );
};

export default CustomerHistoryTable;
