import { FC } from "react";
import { Box, Typography } from "@mui/material";

export interface IPickupDetailsSectionProps {
  totalInvoices: number;
  totalPrice: number;
  totalDiscounts: number;
  totalDue: number;
}

const PickupDetailsSection: FC<IPickupDetailsSectionProps> = ({
  totalInvoices,
  totalPrice,
  totalDiscounts,
  totalDue,
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)", 
        gap: "1rem", 
        marginTop: "-0.8rem",
      }}
    >
      <Typography sx={{ fontSize: "1.14285714286rem" }}>Total Invoices: {totalInvoices}</Typography>
      <Typography sx={{ fontSize: "1.14285714286rem" }}>Total Discounts: ${totalDiscounts.toFixed(2)}</Typography>
      <Typography sx={{ fontSize: "1.14285714286rem" }}>Total Price: ${totalPrice.toFixed(2)}</Typography>
      <Typography sx={{ fontSize: "1.14285714286rem" }}>Total Due: ${totalDue.toFixed(2)}</Typography>
    </Box>
  );
};

export default PickupDetailsSection;
